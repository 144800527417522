<template>
    <section class="section-achievement" >
        <h1>NOS <strong>RÉALISATIONS</strong></h1>
        <p><strong>Marjonat Distribution</strong> vous laisse le soin de découvrir quelques unes de nos réalisations & campagnes d’activations.</p>
    </section>
    <section class="projects-section" id="focus">
   
        <div class="projects-focus-resp" >  
                  
            <div class="img-focus-container">    
                <transition name="fade">
                    <img 
                        :src="$store.state.PROJECTS.find(e => e.id ==  $store.state.PROJECT_FOCUS).img"
                        alt="focus image" 
                        class="img-focus" 
                        :key="$store.state.PROJECTS.find(e => e.id ==  $store.state.PROJECT_FOCUS).img"
                    />
                </transition>      
            </div>     
             
        </div> 
        <div class="projects-filter-container resp">
            <div :class="['btn', { focus : filter === 0}]" @click="filter = 0">Tous</div>
            <div :class="['btn', { focus : filter === 1}]" @click="filter = 1">Industriels</div>
            <div :class="['btn', { focus : filter === 2}]" @click="filter = 2">Divertissements</div>
            <div :class="['btn', { focus : filter === 3}]" @click="filter = 3">Autres</div>
        </div>
        <div class="projects-logo-container">
            <div class="projects-filter-container">
                <div :class="['btn', { focus : filter === 0}]" @click="filter = 0">Tous</div>
                <div :class="['btn', { focus : filter === 1}]" @click="filter = 1">Industriels</div>
                <div :class="['btn', { focus : filter === 2}]" @click="filter = 2">Divertissements</div>
                <div :class="['btn', { focus : filter === 3}]" @click="filter = 3">Autres</div>
            </div>
            <div class="projects-logo">
                <div    
                    class="project-item"
                    v-for="(item, idx) in projects"
                    :key="idx"
                    @click="setProject(item.id)"
                >
                    <img :src="item.logo" alt="logo" class="project-logo">
                </div>
            </div>
        </div>
        <div class="projects-focus">
            <div class="img-focus-container">
                <transition name="fade">
                    <img 
                        :src="$store.state.PROJECTS.find(e => e.id ==  $store.state.PROJECT_FOCUS).img"
                        alt="focus image"
                        class="img-focus"
                        :key="$store.state.PROJECTS.find(e => e.id ==  $store.state.PROJECT_FOCUS).img"
                    />
                </transition>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name : "Achievement",
    data() {
        return {
            filter : 0
        }
    },
    methods : {
        setProject(id) {
            this.$store.commit("setProject", id);
            if(window.innerWidth < 768) {
                this.$router.push({ name : 'Achievement', hash : '#focus'})
            }
        },
    },
    computed : {
        projects() {
            let allProjects = this.$store.state.PROJECTS;
            if(this.filter === 1) {
                return allProjects.filter(e => e.filter === 1);
            }else if(this.filter === 2) {
                return allProjects.filter(e => e.filter === 2);
            }else if(this.filter === 3) {
                return allProjects.filter(e => e.filter === 3);
            }else{
                return allProjects;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
    .section-achievement {
        padding-top: 180px;
        padding-bottom:80px;
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background:$mainColor;
    }

    .section-achievement h1 {
        text-align: center;
        padding:0 20px;
        margin: 0 0 20px 0;
        color:#fff;
        font-size:40px;
        font-weight: 300;

    }

    .section-achievement p {
        text-align: center;
        padding:0 20px;
        margin: 0;
        color:#fff;
        font-size:20px;
        font-weight: 400;

    }

    .projects-section {
        display: flex;
        // align-items: center;
    }

    .projects-logo-container {
        width:calc(98% - 450px);
        margin:40px 1%;
    }

    .projects-logo {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

    }

    .project-item {
        width:250px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        margin:20px;
        box-shadow: 0 0 2px 2px rgba(0,0,0,0.2);
    }
    
    .project-item img {
        max-width: 100%;
        transition:0.5s;
        filter:grayscale(100%);
    }

    .project-item:hover img {
        filter:grayscale(0);
    }

    .projects-focus {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: sticky;
        right:0;
        top:0;
        bottom:0;
        background-image: url("./../assets/bg-mockup.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height:100vh
    }

    .img-focus-container {
        position: relative;
        width:450px;
        height:90vh;
        display: flex;
        justify-content: center;
    }

    .img-focus {
        max-width: 80%;
        padding-top:80px;
        padding-bottom:20px;
        position:absolute;
    }

    .img-focus {
        transition:0.5s;
    }

    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }

    .projects-focus-resp {
        display: none;
    }

    .projects-filter-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom:20px;
    }

    .btn {
        margin:10px;
    }

    .btn.focus {
        background:$mainColor;
        color:#fff;
    }
    .projects-filter-container.resp {
        display: none;
    }

    @media screen and (max-width:1280px) {
        .img-focus-container {
            position: relative;
            width:350px;
            height:90vh;
            display: flex;
            justify-content: center;
        }

        .projects-logo-container {
            width:calc(98% - 350px);
        }

        
    }

    @media screen and (max-width:768px) {
        .projects-focus {
            display: none;
        }

        .projects-logo-container {
            width:98%;
        }

        .project-item {
            width:150px;
            margin:10px;
        }

        .projects-focus-resp{
            display: flex;
            justify-content: center;
            padding:60px 0 20px 0;
        }

        .projects-filter-container {
            display: none;
        }

        .projects-filter-container.resp {
            display: flex;
        }

        .projects-section {
            flex-direction: column;
            align-items: center;
        }

        .projects-focus-resp img {
            max-width: 80%;
        }

        .img-focus-container{
            height:500px;
            width:340px;
        }

        .img-focus {
            padding-top: 20px;
        }
    }
</style>